import axios from "@axios";

import {
  defaultList,
  fetchAll,
  defaultAdd,
  defaultGet,
  defaultUpdate,
  defaultDestroy,
} from "@/store/services";

const link = "/settings/measures";

function fetchAllMeasures(payload) {
  return fetchAll("measures", payload);
}

function list(params) {
  return defaultList(link, params);
}

function get(payload) {
  return defaultGet(link, payload);
}

function add(payload) {
  return defaultAdd(link, payload.data);
}

function update(payload) {
  return defaultUpdate(link, { id: payload.id, ...payload.data });
}

function destroy(measureID) {
  return defaultDestroy(link, measureID);
}

export default {
  fetchAllMeasures,
  list,
  get,
  add,
  update,
  destroy,
};
