import service from "@/store/services/products-service";
import { status } from "@/utils/constants/httpStatusCode";
import { handleErrors } from "@/utils/handleApiResponse";

const state = {
  list: [],
  properties: null,
  item: null,
  meta: {},
  errors: null,
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, item) => {
    state.item = item;
  },
  SET_PROPERTIES: (state, props) => {
    state.properties = props;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_ERRORS: (state, errors) => {
    state.errors = errors;
  },
};

const actions = {
  async fetchAllItems({ commit, dispatch }, params) {
    try {
      const list = await service.getAllProducts(params);
      commit("SET_LIST", list);
    } catch (errors) {
      commit("SET_ERRORS", errors);
    }
  },
  async fetchItemsList(ctx, params) {
    try {
      const { list, meta } = await service.list(params);
      ctx.commit("SET_LIST", list);
      ctx.commit("SET_META", meta);
    } catch ({ response }) {
      handleErrors(
          ctx,
          response,
          "Un erreur est survenue lors de la récupération des produits."
      );
    }
  },
  async fetchItemProperties(ctx, params) {
    try {
      const properties = await service.getProperties(
        params
      );

      ctx.commit("SET_PROPERTIES", properties);
    } catch ({ response }) {
      handleErrors(
        ctx,
        response,
        "Un erreur est survenue lors de la récupération des propriétés du produit."
      );
    }
  },
  async fetchItem(ctx, params) {
    try {
      const item = await service.get(params);
      ctx.commit("SET_RESOURCE", item);
    } catch ({ response }) {
      handleErrors(
          ctx,
          response,
          "Un erreur est survenue lors de la récupération du produit."
      );
    }
  },
  async addItem(ctx, params) {
    return service
      .add(params)
      .then((response) => {
        ctx.commit("SET_RESOURCE", response);
        response.message = "Votre produit a été créé avec succès.";
        return response;
      })
      .catch(({ response }) => {
        handleErrors(ctx, response);
      });
  },
  async updateItem({ commit, dispatch }, params) {
    return service
      .update(params)
      .then((response) => {
        response.message = "Votre produit a été modifié avec succès.";
        commit("SET_RESOURCE", response.resource);
        return response;
      })
      .catch(({ response }) => {
        commit("SET_ERRORS", {
          error:
            response.status !== status.UNPROCESSABLE_ENTITY
              ? {}
              : response.data.error,
          message: "Un erreur est survenue lors de la mise à jour du produit.",
        });
      });
  },
  async destroyItem({ commit, dispatch }, params) {
    return service
      .destroy(params)
      .then((response) => response)
      .catch((error) => error);
  },
};

const getters = {
  all: (state) => state.list,
  item: (state) => state.item,
  allProperties: (state) => state.properties ? state.properties : {},
  meta: (state) => state.meta,
  errors: (state) => state.errors,
};

const item = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default item;
