import service from '@/store/services/fund-histories-service';
import {handleErrors} from "@/utils/handleApiResponse";

const ACCOUNT_SLUG = 'banque';

const state = {
    balance: 0,
    default_cash_register: null,
    current_sub_cr: null,
    list: [],
    accounts_list: [],
    fundHistory: {},
    meta: {},
    errors: null,
};

const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_RESOURCE: (state, folder) => {
        state.folder = folder;
    },
    SET_CASH_REGISTER_INFOS: (state, cash_register) => {
        state.default_cash_register = cash_register;
    },
    SET_ACCOUNTS_LIST: (state, accounts) => {
        state.accounts_list = accounts;
    },
    SET_SUB_CASH_REGISTER_INFOS: (state, sub_cr) => {
        state.current_sub_cr = sub_cr;
    },
    SET_META: (state, meta) => {
        state.meta = meta;
    },
    SET_BALANCE: (state, balance) => {
        state.balance = balance;
    },
    SET_ERRORS: (state, errors) => {
        state.errors = errors;
    },
};

const actions = {
  async fetchFundHistories({ commit, dispatch }, params) {
    try {
      const { list, meta, balance } = await service.list(params);
      commit("SET_LIST", list);
      commit("SET_META", meta);
      commit("SET_BALANCE", balance);
    } catch (error) {
      return error;
    }
  },
  async fetchFundHistory({ commit, dispatch }, params) {
    const fundHistory = await service.get(params);
    commit("SET_RESOURCE", fundHistory);
    return fundHistory;
  },
  async loadCashRegister({ commit}) {
    try {
      const cashRegister = await service.loadCashRegister();
      commit("SET_CASH_REGISTER_INFOS", cashRegister);
    } catch ({ response }) {
      handleErrors({ commit }, response);
    }
  },
    async getAccounts({ commit}) {
    try {
      const accounts_list = await service.fetchAccounts();
      commit("SET_ACCOUNTS_LIST", accounts_list);
    } catch ({ response }) {
      handleErrors({ commit }, response);
    }
  },
  async addFundHistory(ctx, params) {
    try {
      const response = await service.add(params);
      ctx.commit("SET_RESOURCE", response);
      response.message = "Transaction effectué avec succès";
      return response;
    } catch (error) {
        handleErrors(ctx, response);
    }
  },
    async addProvision({ commit, dispatch }, payload) {
    try {
      const response = await service.providingSubCR(payload);
      commit("SET_SUB_CASH_REGISTER_INFOS", response);
    } catch ({ response }) {
      handleErrors({ commit }, response);
    }
  },
  updateFundHistory({ commit, dispatch }, params) {
    return service.update(params).then((fundHistory) => {
      commit("SET_RESOURCE", fundHistory);
    });
  },
  destroyFundHistory({ commit, dispatch }, params) {
    return service.destroy(params);
  },
  getStatistic({ commit, dispatch }, params) {
    return service.statistics(params);
  },
};

const getters = {
    balance: (state) => {
        // if (state.list.length > 0) {
        //     return state.list.reduce(
        //         (prevFh, currFh) => {
        //             let d1 = new Date(prevFh.created_at), d2 = new Date(currFh.created_at)
        //             if (d1 > d2) return prevFh
        //             return currFh
        //         }).balance
        // }
        return state.balance
    },
    cashRegister: (state) => state.default_cash_register,
    accounts: (state) => state.accounts_list,
    selectedSubCR: (state) => state.current_sub_cr,
    transacs: (state) => ({
        debits: transacData(state.list, 'debit'),
        credits: transacData(state.list, 'credit'),
    }),
    total: (state) => state.meta?.total !== undefined ? state.meta?.total : 0,
    requests: (state) => state.list.filter(fh => {
        return fh.status === 'pending' || fh.status === 'approved'
    }),
    // check if the default cash register is related to a single manager
    // and then load it as cash register without sub cash
    // This is to be able to handle a single sub cash register when it comes to assignee cash register
    checkDefaultSubCashRegister: (state) => state.default_cash_register && state.default_cash_register.hasOwnProperty('manager_id'),
    errors: (state) => state.errors,
}

const fundHistory = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
const transacData = (data, transac) => {
    return data.filter(fh => fh[transac] > 0).reduce((prevVal, nextVal) => {
        return prevVal + nextVal[transac]
    }, 0)
};
export default fundHistory;