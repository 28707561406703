import axios from "@axios";
// Axios Mock Adapter
import '@/@fake-db/db'

import {
  defaultList,
  fetchAll,
  defaultAdd,
  defaultGet,
  defaultUpdate,
  defaultDestroy,
} from "@/store/services";

const link = "/stock-management/stores";

function list(query) {
  return defaultList(link, query);
}
function fetchAllStores() {
  return fetchAll('stores');
}

function fetchAllStoreItems(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(`fetch${link}/${payload.store_id}/products`, {
        params: payload.query,
      })
      .then((response) => {
        response = {
          list: response.data.data,
          meta: response.data.meta,
        }
        return resolve(response);
      })
      .catch((error) => reject(error));
  });
}

function queueItems(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get('/apps/ecommerce/wishlist')
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}

function cartItems(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get('/apps/ecommerce/cart')
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}

function addItemToQueue(productId) {
  return new Promise((resolve, reject) => {
    axios
      .post('/apps/ecommerce/wishlist', { productId })
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}

function removeItemFromQueue(productId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/apps/ecommerce/wishlist/${productId}`)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

function addItemInCart(productId) {
  return new Promise((resolve, reject) => {
    axios
      .post('/apps/ecommerce/cart', { productId })
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

function removeItemFromCart(productId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/apps/ecommerce/cart/${productId}`)
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}

function get(payload) {
  return defaultGet(link, payload);
}

function add(payload) {
  return defaultAdd(link, payload.data);
}

function update(payload) {
  return defaultUpdate(link, { id: payload.id, ...payload.data });
}

function destroy(stockID) {
  return defaultDestroy(link, stockID);
}

export default {
  fetchAllStores,
  list,
  fetchAllStoreItems,
  queueItems,
  cartItems,
  get,
  add,
  addItemToQueue,
  addItemInCart,
  update,
  destroy,
  removeItemFromQueue,
  removeItemFromCart
};
