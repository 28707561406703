import axios from "@axios";

import {
  defaultList,
  fetchAll,
  defaultAdd,
  defaultGet,
  defaultUpdate,
  defaultDestroy,
  options,
} from "@/store/services";

const link = "/stock-management/products";

function list(query) {
  return defaultList(link, query);
}

function getAllProducts() {
  return fetchAll("products");
}

function getProperties(payload) {
  let requestOptions = {
    ...options,
    params: payload,
  };

  return new Promise((resolve, reject) => {
    axios
      .get("properties/products", requestOptions)
      .then(({ data }) => {
        return resolve(data.resource);
      })
      .catch((error) => reject({ ...error }));
  });
}

function get(payload) {
  return defaultGet(link, payload);
}

function add(payload) {
  return defaultAdd(link, payload.data);
}

function update(payload) {
  return defaultUpdate(link, { id: payload.id, ...payload.data });
}

function destroy(folderID) {
  return defaultDestroy(link, folderID);
}

export default {
  getAllProducts,
  list,
  get,
  getProperties,
  add,
  update,
  destroy,
};
