import service from "@/store/services/measures-service";
import { handleErrors } from "@/utils/handleApiResponse";

const state = {
  list: [],
  measure: { name: "", description: "" },
  meta: {},
  errors: null,
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, measure) => {
    state.measure = measure;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_ERRORS: (state, errors) => {
    state.errors = errors;
  },
};

const actions = {
  async allMeasures(ctx, params) {
    try {
      return await service.fetchAllMeasures();
    } catch ({ response }) {
      handleErrors(
          ctx,
          response,
          "Un erreur est survenue lors de la récupération des mesure."
      );
    }
  },

  async fetchMeasuresList(ctx, params) {
    try {
      const { list, meta } = await service.list(params);
      ctx.commit("SET_LIST", list);
      ctx.commit("SET_META", meta);
    } catch ({ response }) {
      handleErrors(
          ctx,
          response,
          "Un erreur est survenue lors de la récupération des mesure."
      );
    }
  },

  async fetchMeasure(ctx, params) {
    try {
      const measure = await service.get(params);
      ctx.commit("SET_RESOURCE", measure);
    } catch ({ response }) {
      handleErrors(
          ctx,
          response,
          "Un erreur est survenue lors de la récupération de la mesure."
      );
    }
  },

  async addMeasure(ctx, params) {
    try {
      const response = await service.add(params);
      ctx.commit("SET_RESOURCE", response);
      return ({ message: "Votre mesure a été enregistrée avec succès!" });
    } catch ({ response }) {
      handleErrors(ctx, response)
    }
  },
  async updateMeasure(ctx, params) {
    try {
      const service = await service.update(params);
      ctx.commit("SET_RESOURCE", service.resource);
      return ({ message: "Votre mesure a été mis à jour avec succès."});
    } catch ({ response }) {
      handleErrors(ctx, response, "Une erreur est survenue lors de la mise à jour de la mesure.")
    }
  },
  async destroyMeasure({ commit, dispatch }, params) {
    //
  },
};

const getters = {
  all: (state) => state.list,
  measure: (state) => {
    return state.measure;
  },
  meta: (state) => state.meta,
  errors: (state) => state.errors,
};

const measure = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default measure;
